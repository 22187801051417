import React, { useState } from 'react';
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import HomePageSlider from "../components/HomePageSlider";
import { StaticImage } from "gatsby-plugin-image";
import FeaturedCard from "../components/newComponents/featuredCard";
import CardsCarousel from "../components/CardsCarousel";
import ProjectCardsCarousel from "../components/projectCardCarousel";
import EditLink from "../components/newComponents/editLink";
import YoutubeEmbed from '../components/newComponents/youtubeEmbed';
import { useDisclosure } from "@chakra-ui/react";
import ModalComponent from '../components/newComponents/modalComponent';
import ImgixFluidImage from '../components/newComponents/ImgixFluidImage';

function IndexPage(props) {
  
  const { isOpen, onOpen, onClose } = useDisclosure();

  let relatedTeamMembersVideos = null;
  props.data.relatedTeamMembersVideos.bodyContent.forEach((bodyContentItem) => {
    if(bodyContentItem["id"] === "24485") {

      relatedTeamMembersVideos = bodyContentItem;
      return false;
    }
  });

  const featuredTeamMembers = relatedTeamMembersVideos.featuredTeamMembers;
  const [featuredPerson] = useState(featuredTeamMembers[Math.floor(Math.random() * featuredTeamMembers.length)])

  let projects = null;
  props.data.projectPosts.bodyContent.forEach((bodyContentItem) => {
    if(bodyContentItem["id"] === "30052") {

      projects = bodyContentItem.project;
      return false;
    }
  });

  return (
    <Layout>
      <HomePageSlider data={props.data.home.carousel} alert={props.data.home.alert} />
      <main className="main">
        <section className="section-boxes section-boxes--secondary">
          <div className="shell">
            <header className="section__head">
              <p className="section__title title h2 text-gray">{props.data.home.homeWhatWeDoTitle}</p>
              <p className="section__subtitle subtitle text-orange">{props.data.home.homeWhatWeDoSlogan}</p>
              <div className="section__head-description" dangerouslySetInnerHTML={{ __html: props.data.home.homeWhatWeDoIntro }}/>
            </header>
            <CardsCarousel cards={props.data.home.homeWhatWeDoEntries}></CardsCarousel>
          </div>
        </section>
        <section className="section-boxes section-boxes--news">
          <div className="shell">
            <header className="section__head">
              <p className="section__title title h2 text-gray">Genesis in the news</p>
              <p className="section__subtitle subtitle text-orange">NEWS &amp; INSIGHTS</p>
            </header>
            <div className="boxes boxes--news">
              <ul>
                <FeaturedCard title={props.data.newsPosts.nodes[0].displayTitle} subtitle={props.data.newsPosts.nodes[0].summary} href={`/${ props.data.newsPosts.nodes[0].uri}`} image={props.data.newsPosts.nodes[0].featureImage[0]}/>
              </ul>
              <CardsCarousel cards={props.data.newsPosts.nodes.slice(1)}></CardsCarousel>
            </div>
            <Link to="/news" className="section__btn btn btn--green">More news</Link>
          </div>
        </section>
        <section className="section-projects">
          <div className="shell">
            <header className="section__head">
              <div className="section__head-inner">
                <figure className="section__head-image hidden-xs w-1/3">
                  <ImgixFluidImage attribution={props.data.home.homePageOurPurposeImage[0].attribution} imageUrl={props.data.home.homePageOurPurposeImage[0].url} focalPoint={props.data.home.homePageOurPurposeImage[0].focalPoint} ar={props.data.home.homePageOurPurposeImage[0].width / props.data.home.homePageOurPurposeImage[0].height}/>
                </figure>
                <div className="section__head-content">
                  <p className="section__title title h2 text-gray">{props.data.home.homeOurPurposeTitle}</p>
                  <p className="section__subtitle subtitle text-orange">{props.data.home.homePageOurPurposeSlogan}</p>
                  <figure className="section__head-image hidden-sm hidden-md hidden-lg">
                    <ImgixFluidImage attribution={props.data.home.homePageOurPurposeImage[0].attribution} imageUrl={props.data.home.homePageOurPurposeImage[0].url} focalPoint={props.data.home.homePageOurPurposeImage[0].focalPoint} />
                  </figure>
                  <div className="section__head-entry richtext-entry">
                    <div dangerouslySetInnerHTML={{ __html: props.data.home.homeOurPurposeSummary }}/>
                  </div>
                  <Link to="/our-purpose" className="section__head-link link-more text-gray-3">Read more</Link>
                </div>
              </div>
            </header>
            <div className="boxes swiper-container js-slider-boxes-projects swiper-container-initialized swiper-container-horizontal">
              <ProjectCardsCarousel cards={projects} limit={3}/>
            </div>
            <Link to="/our-purpose" className="section__btn btn btn--green">More projects</Link>
          </div>
        </section>
        <section className="section-text-image">
          <div className="shell">
            <div className="section__inner">
              <div className="section__content">
                <p className="section__title title h2 text-gray">{props.data.home.homeJoinOurTeamHeading}</p>
                <p className="section__subtitle subtitle text-orange" dangerouslySetInnerHTML={{ __html: props.data.home.homePageJoinOurTeamSlogan }}></p>

                <a href="/" onClick={(event) => { event.preventDefault(); onOpen(); }}>
                  <figure className="section__image hidden-sm hidden-md hidden-lg" >
                    <StaticImage className="section__image-icon z-20" src="../images/ico-play.png" alt=""/>
                    <p className="section__image-text z-30">
                      <span>Why I chose Genesis</span>
                    </p>
                      <div className="relative" style={{width: "100%"}}>
                        <ImgixFluidImage attribution={featuredPerson.featureImage[0].attribution} imageUrl={featuredPerson.featureImage[0].url} focalPoint={featuredPerson.featureImage[0].focalPoint} ar={3/2}/>
                        <span className="absolute bottom-1 left-0 top-0 right-0 bg-gray-500 bg-opacity-30"></span>
                      </div>
                  </figure>
                </a>

                {/* <a href="/" onClick={(event) => { event.preventDefault(); onOpen(); }}>
                  <figure className="section__image hidden-sm hidden-md hidden-lg">
                    <StaticImage className="section__image-icon z-40" src="../images/ico-play.png" alt=""/>
                    <p className="section__image-text z-20">
                      <span>Why I chose Genesisas</span>
                    </p>
                      <ImgixFluidImage attribution={featuredPerson.featureImage[0].attribution} className="relative" imageUrl={featuredPerson.featureImage[0].url} focalPoint={featuredPerson.featureImage[0].focalPoint}/>
                      <span className="absolute bottom-0 left-0 w-full h-full bg-gray-500 bg-opacity-30"></span>
                  </figure>
                </a> */}

                <div className="section__entry">
                  <div dangerouslySetInnerHTML={{ __html: props.data.home.homePageJoinOurTeamText }}/>
                </div>
                <a href={ props.data.home.homePageJoinOurTeamLinkUrl1 } className="section__link link-more text-red">{ props.data.home.homePageJoinOurTeamLinkText1 }</a>
                <br/>
                <a href={ props.data.home.homePageJoinOurTeamLinkUrl2 } className="section__link link-more text-red">{ props.data.home.homePageJoinOurTeamLinkText2 }</a>
              </div>
              <a href="/" onClick={(event) => { event.preventDefault(); onOpen();}}>
                <figure className="section__image hidden-xs" >
                  <StaticImage className="section__image-icon z-20" src="../images/ico-play.png" alt=""/>
                  <p className="section__image-text z-30">
                    <span>Why I chose Genesis</span>
                  </p>
                    <div className="relative" style={{height: "500px", width: "350px"}}>
                      <ImgixFluidImage attribution={featuredPerson.featureImage[0].attribution} imageUrl={featuredPerson.featureImage[0].url} focalPoint={featuredPerson.featureImage[0].focalPoint} ar={7/10}/>
                      <span className="absolute bottom-0 left-0 w-full h-full bg-gray-500 bg-opacity-30"></span>
                    </div>
                </figure>
              </a>
              <ModalComponent isOpen={isOpen} onClose={onClose} title={featuredPerson.displayTitle}>
                <YoutubeEmbed title={featuredPerson.staffCareersVideo.title} url={featuredPerson.staffCareersVideo.url} />
              </ModalComponent>
            </div>
          </div>
        </section>
      </main>   
      <EditLink remoteId={props.data.home.remoteId} sectionHandle={props.data.home.sectionHandle} />
    </Layout>
  )
}

export default IndexPage;

export const data = graphql`
  query Home3 {

    site {
      siteMetadata {
        title
      }
    }

    home: craftHomeHomeEntry {
      id
      sectionHandle
      remoteId
      carousel {
        ... on Craft_carousel_slide_BlockType {
          id
          navText
          linkText
          linkText2
          subhead
          head
          customUrl
          customUrl2
          image {
            ...AssetFragment
          }
          relatedEntry {
            id
            uri
          }
          relatedEntry2 {
            id
            uri
          }
        }
      }
      alert
      homeWhatWeDoTitle
      homeWhatWeDoSlogan
      homeWhatWeDoIntro
      homeWhatWeDoEntries {
        id
        uri
        ... on Craft_whatWeDo_whatWeDo_Entry {
          displayTitle
          title
          summary
          featureImage {
            ...AssetFragment
          }
        }
      }
      homeOurPurposeTitle
      homePageOurPurposeSlogan
      homeOurPurposeSummary
      homePageOurPurposeImage {
        ...AssetFragment
      }


      homePageOurPurposeEntries {
        id
        uri
        ... on Craft_projects_projects_Entry {
          displayTitle
          title
          summary
          featureImage {
            ...AssetFragment
          }
        }
      }

      homeJoinOurTeamHeading
      homePageJoinOurTeamSlogan
      homePageJoinOurTeamText
      homePageJoinOurTeamImage {
        ...AssetFragment
      }
      homePageJoinOurTeamLinkText1
      homePageJoinOurTeamLinkUrl1
      homePageJoinOurTeamLinkText2
      homePageJoinOurTeamLinkUrl2
    }

    projectPosts: craftPagePageEntry(id: {eq: "page_page_Entry:17675:1"}) {
      bodyContent {
        ... on Craft_bodyContent_relatedProjects_BlockType {
          id
          project {
            ... on Craft_projects_projects_Entry {
              displayTitle
              title
              summary
              featureImage {
                ...AssetFragment
              }
              uri
            }
          }
        }
      }
    }

    relatedTeamMembersVideos: craftPagePageEntry(id: {eq: "page_page_Entry:24484:1"}) {
      bodyContent {
        ... on Craft_bodyContent_relatedTeamMembersVideos_BlockType {
          id
          featuredTeamMembers {
            ... on Craft_staff_staff_Entry {
              displayTitle
              slug
              position
              featureImage {
                ...AssetFragment
              }
              staffCareersVideo {
                title
                url
                aspectRatio
              }
            }
          }
        }
      }
    }

    newsPosts: allCraftNewsNewsEntry(
      sort: { order: DESC, fields: postDate }
      limit: 4
    ) {
      nodes {
        id
        title
        displayTitle
        slug
        uri
        postDate
        summary
        featureImage {
          ...AssetFragment
        }
      }
    }

    whatWeDoPosts: allCraftWhatWeDoWhatWeDoEntry(
      sort: { order: DESC, fields: postDate }
      limit: 20
    ) {
      nodes {
        title
        displayTitle
        slug
        uri
        postDate
        summary
        featureImage {
          ...AssetFragment
        }
      }
    }
  }
`;