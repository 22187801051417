import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";

const ModalComponent = ({isOpen, onClose, size="2xl", title, children}) => (
  <Modal isOpen={isOpen} onClose={onClose} size={size}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader className="flex justify-between items-center mt-2 mx-2">
        <div>{title}</div>
        <ModalCloseButton style={{position: "static"}}/>
      </ModalHeader>
      <ModalBody className="mb-4">
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default ModalComponent;