import React from 'react';

const YoutubeEmbed = ({title, url, image, ar = "56.25"}) => {

  const id = url ? url.substr(url.length - 11) : "";

  return (
    url ?
      <div style={{position: "relative", width: "100%", height: "0", paddingBottom: `${ar}%`}}>
        <iframe
          title={title}

          src={`https://www.youtube-nocookie.com/embed/${id}`}
          srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/${id}?autoplay=1><img src=${image || `https://i.ytimg.com/vi/${id}/hqdefault.jpg`} alt='${title}'><span>▶</span></a>`}
          
          frameBorder="0"
        
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"

          style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0",}}
        ></iframe>
      </div>
    :
    <></>
  );
}

export default YoutubeEmbed;