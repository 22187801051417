import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from "react";
import Card from "./newComponents/card";

export default class ProjectCardsCarousel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visibleSlides: 3,
      carouselHeight: null,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
    setTimeout(() => {
      this.handleWindowResize();
    })

  }

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1024) {
        this.setState({visibleSlides: 0});
      } else if (window.innerWidth >= 768) {
        this.setState({visibleSlides: 2});
      } else {
        this.setState({visibleSlides: 1});
      }
    }
  }

  render() {
    return (
    <>
      {this.props.cards ?
        <div>
        {this.state.visibleSlides === 0 && (
          <div className="not-a-slider boxes boxes--secondary flex flex-wrap">
            {this.props.cards.map((item, i) => {
                return (
                  i < (this.props.limit || this.props.cards.length) ? <Card subtitle={item.summary} key={`slidercard-${i}`} image={item.featureImage[0]} title={item.title} href={`/${item.uri}`} color={1}/> : <></>
                )
              }
            )}
          </div>
        )}

        {this.state.visibleSlides !== 0 && (

          <div className="is-a-slider boxes boxes--secondary">
            <CarouselProvider
              totalSlides={this.props.cards.length}
              visibleSlides={this.state.visibleSlides}
              isIntrinsicHeight={true}
            >
              <Slider>
                {this.props.cards.map((item, i) => {
                    return (
                      i < (this.props.limit || this.props.cards.length) ?
                      <Slide key={`slide-${i}`} className="px-4">
                        <Card subtitle={item.summary} image={item.featureImage[0]} title={item.displayTitle} href={`/${item.uri}`} color={1}/>
                      </Slide>
                      :
                      <></>
                    )
                  }
                )}
              </Slider>
              <DotGroup className="text-center" />
            </CarouselProvider>
          </div>

        )}
      </div>
      :
      <></>
      }
      </>
      

    )

  }
}